<template>
<div class="report">
    <div class="card bg-opacity-0">
        <div class="card-header border-0 pt-6 bg-white row m-0">
            <div class="d-flex p-0">
                <div class="card-title row text-gray-600">
                    <div class="d-flex p-0 align-items-center position-relative mb-4">
                        <span class="text-gray-500 fw-bolder text-hover-primary rounded-1 cursor-pointer me-6 fs-6" @click="router.go(-1)"><i class="fas fa-arrow-left me-2 text-gray-500 fw-bolder"></i>BACK</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body p-0 tab-content bg-transparent">
            <LoadingSummary v-if="loadingSummary" />
            <div v-else>
                <div class="summary">
                    <div class="summary-list-fixed row m-0">
                        <div class="col-xs-12 col-sm-6 col-md-3 col-lg-4 fs-4 fw-bolder text-gray-700">
                            <div class="d-flex">
                                <span class="text-uppercase">Total Transaksi</span>
                                <span class="ms-auto">{{ summary.total_inv }}</span>
                            </div>
                            <div class="d-flex">
                                <span class="text-uppercase">Total Qty</span>
                                <span class="ms-auto text-nowrap">{{ summary.total_qty }} PCS</span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 fs-4 fw-bolder text-gray-700">
                            <div class="d-flex">
                                <span class="text-uppercase">Total Harga Jual</span>
                                <span class="ms-auto">{{ formatIDR(summary.total_gross, { prefix: 'Rp'}) }}</span>
                            </div>
                            <div class="d-flex">
                                <span class="text-uppercase">Total Diskon</span>
                                <span class="ms-auto">{{ formatIDR(summary.total_discount, { prefix: 'Rp' }) }}</span>
                            </div>
                        </div>
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 fs-4 fw-bolder text-gray-700">
                            <div class="d-flex">
                                <span class="text-uppercase">Total NETT</span>
                                <span class="ms-auto">{{ formatIDR(summary.total_nett, { prefix: 'Rp'}) }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!--begin::Accordion-->
                <div class="accordion mb-4" id="kt_accordion_1">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_1_header_1">
                      <button
                        class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_1_body_1"
                        aria-expanded="true"
                        aria-controls="kt_accordion_1_body_1"
                      >
                        Toko
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_1_body_1"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_1_header_1"
                      data-bs-parent="#kt_accordion_1"
                    >
                        <div class="accordion-body p-0">
                            <div class="summary m-0">
                                <div class="summary-list-fixed row m-0">
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.store" :key="i">
                                        <div class="d-flex">
                                            <span class="text-uppercase">{{ o.store_name }}</span>
                                            <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                                        </div>
                                        <div class="d-flex text-gray-400 text-uppercase">
                                            <span>Qty</span>
                                            <span class="ms-auto text-nowrap">{{ o.qty }} PCS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->

                <!--begin::Accordion-->
                <div class="accordion mb-4" id="kt_accordion_2">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_2_header_2">
                      <button
                        class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_2_body_2"
                        aria-expanded="true"
                        aria-controls="kt_accordion_2_body_2"
                      >
                        Tipe Produk
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_2_body_2"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_2_header_2"
                      data-bs-parent="#kt_accordion_2"
                    >
                        <div class="accordion-body p-0">
                            <div class="summary m-0">
                                <div class="summary-list-fixed row m-0">
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.tipe_produk" :key="i">
                                        <div class="d-flex">
                                            <span class="text-uppercase">{{ o.tipe_produk }}</span>
                                            <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                                        </div>
                                        <div class="d-flex text-gray-400 text-uppercase">
                                            <span>Qty</span>
                                            <span class="ms-auto text-nowrap">{{ o.qty }} PCS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->

                <!--begin::Accordion-->
                <div class="accordion mb-4" id="kt_accordion_3">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_3_header_3">
                      <button
                        class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_3_body_3"
                        aria-expanded="true"
                        aria-controls="kt_accordion_3_body_3"
                      >
                        Metode Pembayaran
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_3_body_3"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_3_header_3"
                      data-bs-parent="#kt_accordion_3"
                    >
                        <div class="accordion-body p-0">
                            <div class="summary m-0">
                                <div class="summary-list-fixed row m-0">
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.payment" :key="i">
                                        <div class="d-flex">
                                            <span class="text-uppercase">{{ o.payment }}</span>
                                            <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                                        </div>
                                        <div class="d-flex text-gray-400 text-uppercase">
                                            <span>Qty</span>
                                            <span class="ms-auto text-nowrap">{{ o.qty }} PCS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->

                <!--begin::Accordion-->
                <div class="accordion mb-4" id="kt_accordion_4">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_4_header_4">
                      <button
                        class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_4_body_4"
                        aria-expanded="true"
                        aria-controls="kt_accordion_4_body_4"
                      >
                        Brand
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_4_body_4"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_4_header_4"
                      data-bs-parent="#kt_accordion_4"
                    >
                        <div class="accordion-body p-0">
                            <div class="summary m-0">
                                <div class="summary-list-fixed row m-0">
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.brand" :key="i">
                                        <div class="d-flex">
                                            <span class="text-uppercase">{{ o.brand_name }}</span>
                                            <span class="ms-auto">{{ o.total ? formatIDR(o.total, { prefix: 'Rp'}) : 0 }}</span>
                                        </div>
                                        <div class="d-flex text-gray-400 text-uppercase">
                                            <span>Qty</span>
                                            <span class="ms-auto text-nowrap">{{ o.qty }} PCS</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->

                <!--begin::Accordion-->
                <div class="accordion mb-4" id="kt_accordion_5">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_5_header_5">
                      <button
                        class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_5_body_5"
                        aria-expanded="true"
                        aria-controls="kt_accordion_5_body_5"
                      >
                        Kategori
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_5_body_5"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_5_header_5"
                      data-bs-parent="#kt_accordion_5"
                    >
                        <div class="accordion-body p-0">
                            <div class="summary m-0">
                                <div class="summary-list-fixed row m-0">
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.category" :key="i">
                                        <div class="d-flex">
                                            <span class="text-uppercase">{{ o.category }}</span>
                                            <span class="ms-auto text-nowrap">{{ o.qty }}</span>
                                        </div>
                                        <div class="py-2">
                                            <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in o.brand.slice(0, 2)" :key="i">
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div>{{ item.brand_name }}</div>
                                                        <div>{{ item.qty }}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <span class="text-primary text-hover-dark fs-7 cursor-pointer" @click="getDetailCategory(o)">Lihat detail</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->

                <!--begin::Accordion-->
                <div class="accordion mb-4" id="kt_accordion_6">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_6_header_6">
                      <button
                        class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_6_body_6"
                        aria-expanded="true"
                        aria-controls="kt_accordion_6_body_6"
                      >
                        Warna
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_6_body_6"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_6_header_6"
                      data-bs-parent="#kt_accordion_6"
                    >
                        <div class="accordion-body p-0">
                            <div class="summary m-0">
                                <div class="summary-list-fixed row m-0">
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.color" :key="i">
                                        <div class="d-flex">
                                            <span class="text-uppercase">{{ o.color }}</span>
                                            <span class="ms-auto text-nowrap">{{ o.qty }}</span>
                                        </div>
                                        <div class="py-2">
                                            <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in o.category.slice(0, 2)" :key="i">
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div>{{ item.category }}</div>
                                                        <div>{{ item.qty }}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <span class="text-primary text-hover-dark fs-7 cursor-pointer" @click="getDetailColor(o)">Lihat detail</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->

                <!--begin::Accordion-->
                <div class="accordion mb-4" id="kt_accordion_7">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="kt_accordion_7_header_7">
                      <button
                        class="accordion-button fs-4 fw-bolder bg-white text-gray-700 text-hover-primary text-uppercase"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_7_body_7"
                        aria-expanded="true"
                        aria-controls="kt_accordion_7_body_7"
                      >
                        Ukuran
                      </button>
                    </h2>
                    <div
                      id="kt_accordion_7_body_7"
                      class="accordion-collapse collapse"
                      aria-labelledby="kt_accordion_7_header_7"
                      data-bs-parent="#kt_accordion_7"
                    >
                        <div class="accordion-body p-0">
                            <div class="summary m-0">
                                <div class="summary-list-fixed row m-0">
                                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3 fs-4 fw-bolder text-gray-700" v-for="(o, i) in summary.size" :key="i">
                                        <div class="d-flex">
                                            <span class="text-uppercase">{{ o.size }}</span>
                                            <span class="ms-auto text-nowrap">{{ o.qty }}</span>
                                        </div>
                                        <div class="py-2">
                                            <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in o.category.slice(0, 2)" :key="i">
                                                <li>
                                                    <div class="d-flex justify-content-between">
                                                        <div>{{ item.category }}</div>
                                                        <div>{{ item.qty }}</div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <span class="text-primary text-hover-dark fs-7 cursor-pointer" @click="getDetailSize(o)">Lihat detail</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <!--end::Accordion-->
            </div>
        </div>
    </div>

    <el-dialog v-model="modalCategory" :title="tempData.category.category + ' (' + tempData.category.qty + ')'" width="400px" :close-on-click-modal="true" v-if="tempData.category">
        <div class="py-2">
            <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in tempData.category.brand" :key="i">
                <li>
                    <div class="d-flex justify-content-between">
                        <div>{{ item.brand_name }}</div>
                        <div>{{ item.qty }}</div>
                    </div>
                </li>
            </ul>
        </div>
    </el-dialog>   

    <el-dialog v-model="modalColor" :title="tempData.color.color + ' (' + tempData.color.qty + ')'" width="400px" :close-on-click-modal="true" v-if="tempData.color">
        <div class="py-2">
            <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in tempData.color.category" :key="i">
                <li>
                    <div class="d-flex justify-content-between">
                        <div>{{ item.category }}</div>
                        <div>{{ item.qty }}</div>
                    </div>
                </li>
            </ul>
        </div>
    </el-dialog>

    <el-dialog v-model="modalSize" :title="tempData.size.size + ' (' + tempData.size.qty + ')'" width="400px" :close-on-click-modal="true" v-if="tempData.size">
        <div class="py-2">
            <ul class="ps-3 mt-1 text-gray-600 fs-7 text-uppercase" v-for="(item, i) in tempData.size.category" :key="i">
                <li>
                    <div class="d-flex justify-content-between">
                        <div>{{ item.category }}</div>
                        <div>{{ item.qty }}</div>
                    </div>
                </li>
            </ul>
        </div>
    </el-dialog>

</div>
</template>

<script>
import { defineComponent, onBeforeMount, ref, reactive, watchEffect, watch, computed, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import { ElLoading } from 'element-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import fileDownload from 'js-file-download';
import Axios from 'axios'
import moment from 'moment'
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import LoadingSummary from "@/components/LoadingSummary.vue"

export default defineComponent({
    components: { 
        //Datatable,
        //Field,
        //Form,
        //ErrorMessage,
        //'inner-image-zoom': InnerImageZoom,
        LoadingSummary,
    },
    setup() {
        const route = useRoute()
        const router = useRouter()

        const img_url_mockup = ref(process.env.VUE_APP_IMAGE_MOCKUPS)
        const img_url_desain= ref(process.env.VUE_APP_IMAGE_DESAINS)

        const store = useStore()

        const loadingSubmit = ref(false)
        const loadingTable = ref(false)
        const loadingSummary = ref(false)
        const tableRef = ref(null)

        const modalCategory = ref(false)
        const modalColor = ref(false)
        const modalSize = ref(false)

        const summary = ref({
          total_item: 0,
          total_qty: 0,
          total_gross: 0,
          total_nett: 0,
          total_inv: 0,
          store: 0,
          tipe_produk: [],
          brand: [],
          category: [],
          artikel: [],
          color: [],
          size: [],
          payment: [],
          project: null,
        })

        const tempData = reactive({
            category: null,
            color: null,
            size: null,
        })

        const getSummary = async () => {
            try {
                loadingSummary.value = true

                await ApiService.get("summary_penjualan")
                  .then(async({ data }) => {
                    summary.value.total_inv = data.data.total_inv || 0
                    summary.value.total_item = data.data.total_item || 0
                    summary.value.total_qty = data.data.total_qty
                    summary.value.total_discount = data.data.total_discount
                    summary.value.total_gross = data.data.total_gross
                    summary.value.total_nett = data.data.total_nett
                    summary.value.store = data.data.store
                    summary.value.tipe_produk = data.data.tipe_produk
                    summary.value.brand = data.data.brand
                    summary.value.category = data.data.category
                    summary.value.artikel = data.data.artikel
                    summary.value.color = data.data.color
                    summary.value.size = data.data.size
                    summary.value.payment = data.data.payment
                    summary.value.projectf = data.data.project
                  })
                  .catch(({ response }) => {
                    throw new Error(response.data.messages.error)
                  });

              } catch(e) {
                console.log(e)
                Swal.fire({
                    title: e.message,
                    icon: "error",
                    buttonsStyling: false,
                    showConfirmButton: false,
                    timer: 2000,
                })
            }

            loadingSummary.value = false
        }

        const getDetailCategory = (data) => {
            try {
                console.log(data)
                tempData.category = data
                modalCategory.value = true

            } catch(e) {
                console.error(e)
            }
        }

        const getDetailColor = (data) => {
            try {
                tempData.color = data
                modalColor.value = true

            } catch(e) {
                console.error(e)
            }
        }

        const getDetailSize = (data) => {
            try {
                tempData.size = data
                modalSize.value = true

            } catch(e) {
                console.error(e)
            }
        }

        onBeforeMount(() => {
            setCurrentPageBreadcrumbs(route.meta.title, ['Laporan Penjualan', route.meta.title]);

            getSummary()
        })

        return {
            router,
            formatIDR, moment,
            loadingSubmit, loadingTable, loadingSummary, tableRef, img_url_mockup, img_url_desain,
            modalCategory, modalColor, modalSize,
            tempData, getDetailCategory, getDetailColor, getDetailSize, summary,
        }
    }
})
</script>